import React, { useEffect, useState } from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledKeyMetrics = styled.section`
  max-width: 1150px;
  margin: 0 auto;
  padding: 0  0 60px 0;
  background-color: ${colors.white};
  color: ${colors.charcoal};
  text-align: center;

  ${breakpoint.medium`
    padding: 0 0 100px 0;
  `}

  ${Container} {
    max-width: 1150px;
    margin: 0 auto;
     }

  h2 {
    color: ${colors.charcoal};
    padding-bottom: 2rem;    

  ${breakpoint.small`
    padding-bottom: 2.5rem;    
  `}

  ${breakpoint.medium`
    padding-bottom: 3rem;    
  `}

  }

    p {
      max-width: 730px;
      margin: 0 auto;
      color: ${colors.charcoal};
    }

  .metrics__grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    ${breakpoint.small`
      margin: 0;
    `}
  }

  .grid__item {
    width: 100%;
    margin-bottom: 40px;
    box-sizing: border-box;

    &:last-child {
      margin-bottom: 0;
    }

    ${breakpoint.small`
      width: 50%;
      padding: 0 20px;

      &:nth-last-child(-n + 3) {
        margin-bottom: 0;
      }
    `}

    ${breakpoint.medium`
      width: 25%;

      &:nth-last-child(-n + 4) {
        margin-bottom: 0;
      }
    `}

    ${breakpoint.medium`
      margin-bottom: 110px;
    `}
  }

  .metrics {
    margin: 40px 0 20px;
    color: ${colors.charcoal};
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .metrics-details {
    p {
      font-weight: 600;
      line-height: 22px;
      font-size: 16px;
    }
    
    h3 {
      color: ${colors.orange};
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 5px;

      ${breakpoint.medium`
        font-size: 50px;
		margin-bottom: 10px;
      `}
    }
    
	.dollar:before { content:"$"}
	.dollar:after { content:"M+"}
	.devices:after { content:"K+"}
	.plus:after { content:"+"}

  }
`


const KeyMetrics = () => {

  const Counter = ({ end, formatStyle }) => {
    const [count, setCount] = useState(0);
    const increment = Math.round(end / 100);
  
    const doIncrement = () => {
      if (count < end) {
        const timer = setInterval(
          () => setCount(count < end - increment ? count + increment : end),
          60
        );
        return () => clearInterval(timer);
      }
    };
  
    useEffect(doIncrement, [count, end, increment, formatStyle])
    if (formatStyle) {
      return <h3 className={formatStyle}>{count}</h3>
    }
    return <h3>{count}</h3>
  }
  
  return (
    <StyledKeyMetrics data-aos="fade-up">
      <Container>
        <div className="metrics">
          <h2>Key metrics</h2>
          <div className="metrics__grid">
            <div className="grid__item">
              <div className="metrics-details"><Counter end={300} formatStyle={"dollar"} /><p>Funding raised</p></div>
            </div>
            <div className="grid__item">
              <div className="metrics-details"><Counter end={490} formatStyle={"plus"} /><p>Dedicated Visbees</p></div>
            </div>
            <div className="grid__item">
              <div className="metrics-details"><h3>3</h3><p>Awards won</p></div>
            </div>
            <div className="grid__item">
              <div className="metrics-details"><h3>1</h3><p>Shared purpose</p></div>
            </div>
          </div>
        </div>
      </Container>
    </StyledKeyMetrics>
  )
}

export default KeyMetrics